// Add Tailwind CSS and block other parts from loading until Tailwind is loaded
const link = document.createElement('link');
link.rel = 'stylesheet';
link.href = 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';
link.onload = initializeApp;
document.head.appendChild(link);

function initializeApp() {
    import('./style.css')
        .then(() => import('three'))
        .then((THREE) => {
            import('three/examples/jsm/controls/OrbitControls.js')
                .then(({ OrbitControls }) => {
                    import('three/examples/jsm/loaders/GLTFLoader.js')
                        .then(({ GLTFLoader }) => {
                            // Create landing page elements
                            const landingPage = document.createElement('div');
                            landingPage.id = 'landing-page';
                            landingPage.className = 'fixed inset-0 flex justify-center items-center z-50 bg-black overflow-hidden';
                            landingPage.innerHTML = `
                                <video id="background-video" class="fixed inset-0 w-full h-full object-cover -z-10" autoplay loop muted playsinline playbackRate="0.1">
                                    <source src="background_video.m4v" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                                <div id="wave-effect" class="absolute inset-0 z-20 pointer-events-none overflow-hidden"></div>
                                <div class="text-center text-white relative z-30 p-5 max-w-[90%] font-comic-sans">
                                    <h1 class="text-6xl md:text-8xl lg:text-8xl mb-6 animate-fadeInDown font-comic-sans font-extrabold">
                                        <span class="text-white">Magic</span>
                                        <span class="text-red-300">A</span>
                                        <span class="text-yellow-300">l</span>
                                        <span class="text-green-300">p</span>
                                        <span class="text-blue-300">h</span>
                                        <span class="text-indigo-300">a</span>
                                        <span class="text-purple-300">b</span>
                                        <span class="text-pink-300">e</span>
                                        <span class="text-cyan-300">t</span>
                                    </h1>
                                    <div class="bg-white bg-opacity-70 backdrop-blur-md rounded-3xl p-5 border-4 border-blue-300 shadow-lg animate-fadeIn z-50">
                                        <h2 class="text-3xl md:text-4xl lg:text-5xl text-blue-600 font-comic-sans mb-4 z-50">Ready to Play?</h2>
                                        <p class="text-xl md:text-2xl lg:text-3xl text-blue-600 font-comic-sans mb-8">Click on Christmas tree to summon the magic Alphabet!</p>
                                        <button id="enter-button" class="text-lg md:text-xl lg:text-2xl py-3 px-6 bg-gradient-to-r from-blue-400 to-blue-600 text-white rounded-full cursor-pointer transition-all duration-300 transform hover:scale-105 hover:shadow-md hover:animate-bounce font-comic-sans">Let's Go! 🎄</button>
                                    </div>
                                </div>
                                <div id="background-blur" class="fixed inset-0 backdrop-blur-sm z-0"></div>
                                <div class="absolute z-0 w-full h-full bg-gradient-to-br from-blue-400 to-cyan-500 opacity-50"></div> <!-- Overlay added here -->
                            `;
                            document.body.appendChild(landingPage);

                            // Add custom styles
                            const style = document.createElement('style');
                            style.textContent = `
                                @font-face {
                                    font-family: 'Comic Sans MS';
                                    src: url('https://db.onlinewebfonts.com/t/7cc6719bd5f0310be3150ba33418e72e.woff2') format('woff2'),
                                         url('https://db.onlinewebfonts.com/t/7cc6719bd5f0310be3150ba33418e72e.woff') format('woff');
                                    font-weight: normal;
                                    font-style: normal;
                                }
                                body, button, input, select, textarea {
                                    font-family: 'Comic Sans MS', cursive, sans-serif;
                                }
                                @keyframes fadeInDown {
                                    from { opacity: 0; transform: translateY(-50px); }
                                    to { opacity: 1; transform: translateY(0); }
                                }
                                @keyframes fadeIn {
                                    from { opacity: 0; }
                                    to { opacity: 1; }
                                }
                                .animate-fadeInDown { animation: fadeInDown 1s ease-out; }
                                .animate-fadeIn { animation: fadeIn 1s ease-out 0.5s both; }
                                .wave {
                                    position: absolute;
                                    background-color: rgba(173, 216, 230, 0.7);
                                    border-radius: 30%;
                                    opacity: 0.7;
                                }
                                @keyframes waveAnimation {
                                    0%, 100% { transform: translate(0, 0) scale(1); }
                                    50% { transform: translate(var(--translateX), var(--translateY)) scale(1.1); }
                                }
                                @keyframes loadingDots {
                                    0%, 20% { content: '.'; }
                                    40%, 60% { content: '..'; }
                                    80%, 100% { content: '...'; }
                                }
                                #loading-text::after {
                                    content: '';
                                    animation: loadingDots 1.5s infinite;
                                }
                            `;
                            document.head.appendChild(style);

                            // Create wave effect
                            function createWaveEffect() {
                                const waveEffect = document.getElementById('wave-effect');
                                for (let i = 0; i < 10; i++) {
                                    const wave = document.createElement('div');
                                    wave.className = 'wave';
                                    const size = Math.random() * 150 + 50;
                                    wave.style.width = `${size}px`;
                                    wave.style.height = `${size}px`;
                                    wave.style.top = `${Math.random() * 100}%`;
                                    wave.style.left = `${Math.random() * 100}%`;
                                    wave.style.setProperty('--translateX', `${Math.random() * 100 - 50}px`);
                                    wave.style.setProperty('--translateY', `${Math.random() * 50 - 25}px`);
                                    wave.style.animation = `waveAnimation ${Math.random() * 20 + 10}s infinite`;
                                    wave.style.zIndex = '10'; // Ensure waves are in front of the video
                                    waveEffect.appendChild(wave);
                                }
                            }

                            // Call createWaveEffect after a short delay to ensure the wave-effect container is ready
                            setTimeout(createWaveEffect, 100);

                            // Add event listener to enter button
                            document.getElementById('enter-button').addEventListener('click', () => startExperience(THREE, OrbitControls, GLTFLoader));
                            const canvas = document.querySelector('canvas.webgl');
                            if (canvas) {
                                canvas.style.display = 'block';
                            }
                            // Continue with the rest of your initialization code here
                            // ...
                        });
                });
        });
}

// Hide canvas initially
const canvas = document.querySelector('canvas.webgl');
if (canvas) {
    canvas.style.display = 'none';
}

// Create a loading manager
let loadingManager, loader, audioLoader, textureLoader;

// Set up loading text
const loadingText = document.createElement('div');
loadingText.id = 'loading-text';
loadingText.textContent = 'Loading';
loadingText.style.display = 'none';
loadingText.style.position = 'fixed';
loadingText.style.top = '50%';
loadingText.style.left = '50%';
loadingText.style.transform = 'translate(-50%, -50%)';
loadingText.style.fontSize = '48px';
loadingText.style.color = 'white';
document.body.appendChild(loadingText);

// Preload function
function preloadAssets(THREE) {
    loadingText.style.display = 'block';
    const assetsToLoad = [
        { type: 'gltf', path: 'assets/models/snowman/scene.gltf' },
        { type: 'gltf', path: 'assets/models/kids_plastic_alphabet_toy_blocks_free_low_poly.glb' },
        { type: 'texture', path: 'lollypop.png' }
    ];

    alphabet.split('').forEach(letter => {
        assetsToLoad.push({ type: 'gltf', path: `assets/models/objects/${letter.toLowerCase()}.glb` });
        assetsToLoad.push({ type: 'audio', path: `assets/audios/${letter.toLowerCase()}.mp3` });
    });

    assetsToLoad.forEach(asset => {
        switch(asset.type) {
            case 'gltf':
                loader.load(asset.path, () => {}, undefined, (error) => console.error('Error loading GLTF:', error));
                break;
            case 'audio':
                audioLoader.load(asset.path, () => {}, undefined, (error) => console.error('Error loading audio:', error));
                break;
            case 'texture':
                textureLoader.load(asset.path, () => {}, undefined, (error) => console.error('Error loading texture:', error));
                break;
        }
    });
}

// Enter button click event
function startExperience(THREE, OrbitControls, GLTFLoader) {
    console.log("startExperience function called");
    // Remove landing page
    const landingPage = document.getElementById('landing-page');
    if (landingPage) {
        document.body.removeChild(landingPage);
    }

    // Enter fullscreen
    toggleFullScreen();
    
    // Show loading text
    loadingText.style.display = 'block';

    // Initialize THREE-related variables
    loadingManager = new THREE.LoadingManager();
    loader = new GLTFLoader(loadingManager);
    audioLoader = new THREE.AudioLoader(loadingManager);
    textureLoader = new THREE.TextureLoader(loadingManager);

    // Start loading assets
    playBackgroundMusic();
    preloadAssets(THREE);

    // Create container for buttons
    const buttonContainer = document.createElement('div');
    buttonContainer.style.position = 'fixed';
    buttonContainer.style.left = '10px';
    buttonContainer.style.bottom = '10px';
    buttonContainer.style.zIndex = '1000';
    buttonContainer.style.display = 'flex';
    buttonContainer.style.gap = '10px';

    // Create and add toggle fullscreen button
    const toggleFullscreenBtn = document.createElement('button');
    toggleFullscreenBtn.innerHTML = '&#x26F6;'; // Fullscreen icon
    toggleFullscreenBtn.title = 'Toggle Fullscreen';
    toggleFullscreenBtn.style.width = '40px';
    toggleFullscreenBtn.style.height = '40px';
    toggleFullscreenBtn.addEventListener('click', toggleFullScreen);
    buttonContainer.appendChild(toggleFullscreenBtn);

    // Add button container to the body
    document.body.appendChild(buttonContainer);

    // Initialize the scene
    initScene(THREE, OrbitControls, GLTFLoader);
}

// Fullscreen function
const toggleFullScreen = async () => {
    // Check if the device is iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    
    if (isIOS) {
        // For iOS devices, we'll use a different approach
        document.body.style.position = 'fixed';
        document.body.style.top = '0';
        document.body.style.left = '0';
        document.body.style.width = '100%';
        document.body.style.height = '100%';
        document.body.style.overflow = 'hidden';
    } else {
        // For non-iOS devices, try to use the Fullscreen API
        if (!document.fullscreenElement && !document.webkitFullscreenElement) {
            try {
                if (document.documentElement.requestFullscreen) {
                    await document.documentElement.requestFullscreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    await document.documentElement.webkitRequestFullscreen();
                }
            } catch (err) {
                console.log(`Fullscreen request failed: ${err.message}`);
                // Fallback for devices that don't support fullscreen
                document.body.style.position = 'fixed';
                document.body.style.top = '0';
                document.body.style.left = '0';
                document.body.style.width = '100%';
                document.body.style.height = '100%';
                document.body.style.overflow = 'hidden';
            }
        }
    }
    // Change to landscape mode if on mobile
    if (window.screen && window.screen.orientation && window.screen.orientation.lock) {
        window.screen.orientation.lock('landscape').catch(err => {
          console.log(`Unable to lock screen orientation: ${err.message} (${err.name})`);
        });
    }
};

// Background music
const backgroundMusic = new Audio('background_music.mp3');
backgroundMusic.loop = true;
//backgroundMusic.volume = 0.01; // Set volume to 20% (low volume)

// Use a promise to handle audio loading and playing
const playBackgroundMusic = () => {
    return new Promise((resolve, reject) => {
        backgroundMusic.addEventListener('canplaythrough', () => {
            backgroundMusic.play()
                .then(resolve)
                .catch(reject);
        }, { once: true });

        backgroundMusic.addEventListener('error', (e) => {
            reject(new Error(`Audio loading error: ${e.target.error.message}`));
        }, { once: true });

        // Timeout in case the audio takes too long to load
        setTimeout(() => {
            reject(new Error('Audio loading timeout'));
        }, 10000); // 10 seconds timeout

        // Start loading the audio
        backgroundMusic.load();
    });
};

// Custom cursor
document.body.style.cursor = `url('lollypop.png'), auto`;

const mappings = {
    'A': 'G',
    'G': 'A',
    'B': 'F',
    'F': 'B',
    'C': 'E',   
    'E': 'C',
    'H': 'N',
    'N': 'H',
    'I': 'M',
    'M': 'I',
    'J': 'L',
    'L': 'J',
    'O': 'T',
    'T': 'O',
    'P': 'S',
    'S': 'P',
    'Q': 'R',
    'R': 'Q',
    'U': 'z',
    'Z': 'U',
    'V': 'Y',
    'Y': 'V',
    'W': 'X',
    'X': 'W',
    "K": "K",
    "D": "D"
}

const mappnig_offests = {
    'A': [9, 0, -0.5],
    'B': [6, 0, -0.5],
    'C': [3, 0, -0.5],
    'D': [0, 0, -0.5],
    'E': [-3, 0, -0.5],
    'F': [-6, 0, -0.5],
    'G': [-9, 0, -0.5],    
    'H': [10, 0, 2],
    'I': [7, 0, 2],
    'J': [4, 0, 2],
    'K': [1, 0, 2],
    'L': [-2, 0, 2],
    'M': [-5, 0, 2],
    'N': [-8, 0, 2], 
    'O': [6, 0, 4],
    'P': [3, 0, 4],
    'Q': [0, 0, 4],
    'R': [-3, 0, 4],
    'S': [-6, 0, 4],
    'T': [-9, 0, 4],
    'U': [6, 0, 6], 
    'V': [3, 0, 6],
    'W': [0, 0, 6],
    'X': [-3, 0, 6],
    'Y': [-6, 0, 6],
    'Z': [-9, 0, 6],
}

const objectMappings = {
    'A': { position: [400, 90, 100], scale: [100, 100, 100], rotation: [0, 0, 0] , light_intensity: 0.0, name: 'Apple'},
    'B': { position: [400, 80, 100], scale: [2.5, 2.5, 2.5], rotation: [0, 0, 0] , light_intensity: 0, name: 'Ball'},
    'C': { position: [650, 180, 100], scale: [150, 150, 150], rotation: [0, 0, 0] , light_intensity: 0.0, name: 'Car'},
    'D': { position: [400, -20, 100], scale: [4000, 4000, 4000], rotation: [0, 0, 0] , light_intensity: 0.0, name: 'Dinosaur'},
    'E': { position: [400, 0, 100], scale: [5, 5, 5], rotation: [0, 0, 0] , light_intensity: 2, name: 'Eagle'},
    'F': { position: [400, 0, 100], scale: [20, 20, 20], rotation: [0, -1, 0] , light_intensity: 2, name: 'Flowers'},
    'G': { position: [450, 0, 100], scale: [80, 80, 80], rotation: [0, 0, 0] , light_intensity: 0.0, name: 'Guitar'},
    'H': { position: [450, 10, 150], scale: [0.4, 0.4, 0.4], rotation: [-0.05, 1.2, 0.] , light_intensity: 1.0, name: 'House'},
    'I': { position: [400, 100, 150], scale: [900, 900, 900], rotation: [0, 0, 0] , light_intensity: 0, name: 'Icecream'},
    'J': { position: [350, 100, -100], scale: [800, 800, 800], rotation: [0, 0, 0] , light_intensity: 2, name: 'Jellyfish'},
    'K': { position: [350, 0, 100], scale: [1, 1, 1], rotation: [0, -1, 0] , light_intensity: 2, name: 'Kettle'},
    'L': { position: [400, 0, 100], scale: [600, 600, 600], rotation: [0, 0, 0] , light_intensity: 0.1, name: 'Lamp'},
    'M': { position: [400, 0, 100], scale: [1000, 1000, 1000], rotation: [0, 0, 0] , light_intensity: 2, name: 'Mushrooms'},
    'N': { position: [400, 200, 100], scale: [20, 20, 20], rotation: [0.5, 0, 0] , light_intensity: 0, name: 'Net'},
    'O': { position: [400, 90, 100], scale: [2.5, 2.5, 2.5], rotation: [0, -1.5, 0] , light_intensity: 1.0, name: 'Orange'},
    'P': { position: [400, -18, 100], scale: [100, 100, 100], rotation: [0, 0, 0] , light_intensity: 0, name: 'Pencil'},
    'Q': { position: [350, 0, 100], scale: [500, 500, 500], rotation: [0, -1, 0] , light_intensity: 0.0, name: 'Quil'},
    'R': { position: [350, -195, 100], scale: [35, 35, 35], rotation: [0, -1, 0] , light_intensity: 0.0, name: 'Rocket'},
    'S': { position: [400, -10, 100], scale: [300, 300, 300], rotation: [0, -2, 0] , light_intensity: 0.5, name: 'Snowman'},
    'T': { position: [700, 170, -200], scale: [100, 100, 100], rotation: [0, -0.8, 0] , light_intensity: 0, name: 'Train'},
    'U': { position: [400, 150, 80], scale: [1, 1, 1], rotation: [0, -2, 0] , light_intensity: 0, name: 'Umbrella'},
    'V': { position: [300, -300, 80], scale: [100, 100, 100], rotation: [0, 0, 0] , light_intensity: 2, name: 'Violin'},
    'W': { position: [400, 0, 100], scale: [100, 100, 100], rotation: [0, 0, 0] , light_intensity: 2, name: 'Well'},
    'X': { position: [420, 0, 100], scale: [13000, 13000, 13000], rotation: [0, 0, 0] , light_intensity: 0.2, name: 'Xylophone'},
    'Y': { position: [580, 10, 0], scale: [25, 25, 25], rotation: [-0.1, -1, 0] , light_intensity: 0, name: 'Yacht'},
    'Z': { position: [400, 40, 100], scale: [0.05, 0.05, 0.05], rotation: [0, -1.5, 0] , light_intensity: 1.5, name: 'Zebra'}, 
}

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

// The rest of your code remains unchanged
let currentLetterIndex = alphabet.indexOf('Z'); // Start with N as it's mapped to H
function initScene(THREE, OrbitControls, GLTFLoader) {
    console.log("initScene function called");
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }
    const scene = new THREE.Scene()

    // Move the scene up
    scene.position.y = 50;

    // Renderer
    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true,
        alpha: true
    })
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(new THREE.Color(0x000030));
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;

    // Camera
    let camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 2000);
    camera.position.set(0, 300, 700);
    camera.lookAt(scene.position);

    // OrbitControls
    const orbitControls = new OrbitControls(camera, renderer.domElement);
    orbitControls.target.set(150, 250, 0);
    orbitControls.enablePan = false;
    orbitControls.enableDamping = true;
    orbitControls.dampingFactor = 0.2;

    // Restrict vertical rotation
    orbitControls.maxPolarAngle = Math.PI / 2; // Limit to horizontal rotation
    orbitControls.minPolarAngle = Math.PI / 2; // Limit to horizontal rotation
    orbitControls.maxDistance = 1000
    orbitControls.minDistance = 700

    // AmbientLight
    const ambientLight = new THREE.AmbientLight(0x666666);
    scene.add(ambientLight);

    // Additional ambient lights
    const warmAmbientLight = new THREE.AmbientLight(0xffaa00, 0.3);
    scene.add(warmAmbientLight);

    const coolAmbientLight = new THREE.AmbientLight(0x0044ff, 0.3);
    scene.add(coolAmbientLight);

    // SpotLight
    const spotLight = new THREE.SpotLight(0xffffff);
    spotLight.distance = 1000;
    spotLight.position.set(0, 650, 0);
    spotLight.castShadow = true;
    scene.add(spotLight);

    // New SpotLight
    const spotLight2 = new THREE.SpotLight(0xffffff, objectMappings[alphabet[currentLetterIndex]].light_intensity);
    spotLight2.position.set(400, 400, 0);
    spotLight2.target.position.set(400, 0, 100);
    spotLight2.angle = Math.PI / 4; // 45 degrees cone
    spotLight2.penumbra = 0.1;
    spotLight2.decay = 2;
    spotLight2.distance = 15000;
    scene.add(spotLight2);
    scene.add(spotLight2.target);

    // Plane
    const planeGeometry = new THREE.PlaneBufferGeometry(5000, 5000, 1000, 1000);
    const planeMaterial = new THREE.MeshLambertMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide
    });
    let planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
    planeMesh.receiveShadow = true;
    planeMesh.rotation.x = -0.5 * Math.PI;
    planeMesh.position.x = 0;
    planeMesh.position.y = 0;
    planeMesh.position.z = 0;
    planeMesh.name = 'Ground';
    scene.add(planeMesh);

    // Snowman
    const loader = new GLTFLoader();

    let loadedItems = 0;
    const totalItems = 2; // Snowman and Alphabet Blocks

    // Add a flag to track if the user has interacted with the tree
    let hasInteractedWithTree = false;

    // Create a sprite for the instruction text
    let instructionSprite = null;

    function createInstructionSprite() {
        const instructionCanvas = document.createElement('canvas');
        const instructionCtx = instructionCanvas.getContext('2d');
        instructionCanvas.width = 1024;
        instructionCanvas.height = 256;
        instructionCtx.font = 'Bold 48px Comic Sans MS';
        instructionCtx.fillStyle = 'white';
        instructionCtx.textAlign = 'center';
        instructionCtx.textBaseline = 'middle';
        instructionCtx.fillText('Click on the christmas tree to get started!', 512, 128);

        const instructionTexture = new THREE.CanvasTexture(instructionCanvas);
        const instructionMaterial = new THREE.SpriteMaterial({ map: instructionTexture });
        instructionSprite = new THREE.Sprite(instructionMaterial);
        instructionSprite.position.set(0, 400, 0);
        instructionSprite.scale.set(500, 125, 1);
        instructionSprite.name = 'InstructionText';
        scene.add(instructionSprite);
    }

    function updateLoadingProgress() {
        loadedItems++;
        
        if (loadedItems === totalItems) {
            // All items loaded, remove loading text
            loadingText.style.display = 'none';
            // Create and add instruction sprite
            createInstructionSprite();
        }
    }

    loader.load('assets/models/snowman/scene.gltf', (gltf) => {
        const tree = gltf.scene

        tree.position.set(0, 0, 0);
        tree.name = 'Snowman';

        scene.add(tree);
        updateLoadingProgress();
    }, () => {
        // TODO progress
    }, function (error) {
        console.error(error);
        updateLoadingProgress();
    });

    // Alphabet Toy Blocks
    loader.load('assets/models/kids_plastic_alphabet_toy_blocks_free_low_poly.glb', (gltf) => {
        const blocks = gltf.scene;
        blocks.position.set(0, 0, -200); // Adjust position as needed
        blocks.scale.set(50, 50, 50); // Adjust scale as needed
        blocks.name = 'AlphabetBlocks';
        scene.add(blocks);

        // Hide all blocks at start
        blocks.traverse((child) => {
            if (child.isMesh && child.name.includes('AlphaBetCube')) {
                child.visible = false;
            }
        });

        // Forward the Z block (but keep it hidden)
        const zBlock = blocks.getObjectByName(mappings['Z'] + '_AlphaBetCube_0');
        if (zBlock) {
            const present = scene.getObjectByName('Christmas_Present_009_Christmas_Tree_0');
            if (present) {
                zBlock.position.set(present.position.x - 20, -4, 8); // Position a bit left to the present
            } else {
                zBlock.position.set(-12, -4, 8); // Fallback position if present not found
            }
            zBlock.rotation.z = Math.PI / 9;
        }
        updateLoadingProgress();
    }, () => {
        // TODO progress
    }, function (error) {
        console.error(error);
        updateLoadingProgress();
    });

    // Snow
    const SNOW_NUM = 5000;
    const SNOW_MAX_RANGE = 1000;
    const SNOW_MIN_RANGE = 50;
    const SNOW_TEXTURE_SIZE = 32.0;

    let snowVertices = [];
    for (let i = 0; i < SNOW_NUM; i++) {
        const x = Math.floor(Math.random() * SNOW_MAX_RANGE - SNOW_MAX_RANGE / 2);
        const y = Math.floor(Math.random() * SNOW_MAX_RANGE) + SNOW_MIN_RANGE;
        const z = Math.floor(Math.random() * SNOW_MAX_RANGE - SNOW_MAX_RANGE / 2);
        const snow = new THREE.Vector3(x, y, z);
        snowVertices.push(snow);
    }

    const pointGeometry = new THREE.BufferGeometry();
    pointGeometry.setFromPoints(snowVertices)

    const drawRadialGradation = (ctx, canvasRadius, canvasW, canvasH) => {
        ctx.save();
        const gradient = ctx.createRadialGradient(canvasRadius, canvasRadius, 0, canvasRadius, canvasRadius, canvasRadius);
        gradient.addColorStop(0, 'rgba(255,255,255,1.0)');
        gradient.addColorStop(0.5, 'rgba(255,255,255,0.5)');
        gradient.addColorStop(1, 'rgba(255,255,255,0)');
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, canvasW, canvasH);
        ctx.restore();
    }

    const getSnowTexture = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const diameter = SNOW_TEXTURE_SIZE;
        canvas.width = diameter;
        canvas.height = diameter;
        const canvasRadius = diameter / 2;

        drawRadialGradation(ctx, canvasRadius, canvas.width, canvas.height);

        const texture = new THREE.Texture(canvas);
        texture.minFilter = THREE.LinearFilter; // Ensure proper texture filtering
        texture.needsUpdate = true;
        return texture;
    }

    const pointMaterial = new THREE.PointsMaterial({
        size: 8,
        color: 0xffffff,
        vertexColors: false,
        map: getSnowTexture(),
        transparent: true,
        fog: true,
        depthWrite: false
    });

    const velocities = [];
    const speed = SNOW_TEXTURE_SIZE / 10
    for (let i = 0; i < SNOW_NUM; i++) {
        const x = Math.floor(Math.random() * speed - speed / 2) * 0.1;
        const y = Math.floor(Math.random() * speed + speed / 2) * - 0.05;
        const z = Math.floor(Math.random() * speed - speed / 2) * 0.1;
        const snow = new THREE.Vector3(x, y, z);
        velocities.push(snow);
    }

    let snows = new THREE.Points(pointGeometry, pointMaterial);
    snows.geometry.velocities = velocities;
    snows.name = 'Snow';
    scene.add(snows);

    const dropSnows = (timeStamp) => {
        const posArr = snows.geometry.attributes.position.array;
        const velArr = snows.geometry.velocities;

        let index = 0;
        for (let i = 0, l = SNOW_NUM; i < l; i++) {
            let snow = snowVertices[i]
            posArr[index++] = snow.x;
            posArr[index++] = snow.y;
            posArr[index++] = snow.z;

            const velocity = velArr[i];

            const velX = Math.sin(timeStamp * 0.001 * velocity.x) * 0.1;
            const velZ = Math.cos(timeStamp * 0.0015 * velocity.z) * 0.1;

            snow.x += velX;
            snow.y += velocity.y;
            snow.z += velZ;

            if (snow.y < SNOW_MIN_RANGE) {
                snow.y = SNOW_MAX_RANGE + SNOW_MIN_RANGE;
            }
        }

        snows.geometry.attributes.position.needsUpdate = true
    }

    // Resize handle
    window.addEventListener('resize', () => {
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight
        camera.aspect = sizes.width / sizes.height

        camera.updateProjectionMatrix()
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })

    // Raycaster
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    // Shaking animation
    let isShaking = false;
    let shakeTime = 0;
    const shakeDuration = 500; // 2 seconds (slower)
    const shakeIntensity = 0.15; // Higher amplitude

    function shakeObject(object) {
        isShaking = true;
        shakeTime = 0;
    }

    let isSwapping = false; // Flag to prevent multiple swaps

    function swapBlock() {
        if (isSwapping) return; // If already swapping, return
        isSwapping = true; // Set the flag

        const blocks = scene.getObjectByName('AlphabetBlocks');
        if (!blocks) {
            isSwapping = false;
            return;
        }

        // Hide current block
        const currentLetter = alphabet[currentLetterIndex];
        const currentBlock = blocks.getObjectByName(mappings[currentLetter] + '_AlphaBetCube_0');
        if (currentBlock) {
            currentBlock.visible = false;
        }

        // Remove previous object name if exists
        const prevObjectName = scene.getObjectByName(`ObjectName_${currentLetter}`);
        if (prevObjectName) {
            scene.remove(prevObjectName);
        }

        // Remove previous object if exists
        const prevObject = scene.getObjectByName(`Object_${currentLetter}`);
        if (prevObject) {
            scene.remove(prevObject);
        }

        // Move to next letter
        currentLetterIndex = (currentLetterIndex + 1) % alphabet.length;
        const nextLetter = alphabet[currentLetterIndex];

        // Show and position next block
        const nextBlock = blocks.getObjectByName(mappings[nextLetter] + '_AlphaBetCube_0');
        if (nextBlock) {
            nextBlock.visible = true;
            const present = scene.getObjectByName('Christmas_Present_009_Christmas_Tree_0');
            if (present) {
                const randomOffset = {
                    x: (Math.random() - 0.5) * 2,  // Random value between -1 and 1
                    y: (Math.random() - 0.5) * 2,
                    z: (Math.random() - 0.5) * 2
                };
                nextBlock.position.set(
                    mappnig_offests[nextLetter][0] + randomOffset.x,
                    mappnig_offests[nextLetter][1] + randomOffset.y,
                    mappnig_offests[nextLetter][2] + randomOffset.z
                );
                console.log('Next block position:', nextBlock.position);
            } else {
                nextBlock.position.set(
                    -12 + (Math.random() - 0.5) * 2,
                    -4 + (Math.random() - 0.5) * 2,
                    8 + (Math.random() - 0.5) * 2
                );
            }
        }

        // Load corresponding object model
        const objectModelPath = `assets/models/objects/${nextLetter.toLowerCase()}.glb`;
        loader.load(objectModelPath, (gltf) => {
            const object = gltf.scene;
            const objectMapping = objectMappings[nextLetter];
            object.position.set(
                nextBlock.position.x + objectMapping.position[0],
                nextBlock.position.y + objectMapping.position[1],
                nextBlock.position.z + objectMapping.position[2]
            );
            object.scale.set(...objectMapping.scale);
            object.rotation.set(...objectMapping.rotation);
            object.name = `Object_${nextLetter}`;
            scene.add(object);

            // Remove existing object name if it exists
            const existingObjectName = scene.getObjectByName(`ObjectName_${nextLetter}`);
            if (existingObjectName) {
                scene.remove(existingObjectName);
            }

            // Create and add object name
            const objectNameCanvas = document.createElement('canvas');
            const ctx = objectNameCanvas.getContext('2d');
            objectNameCanvas.width = 1024;
            objectNameCanvas.height = 256;
            ctx.font = 'Bold 200px Comic Sans MS';
            ctx.fillStyle = 'white';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            const objectName = objectMapping.name;
            const firstLetter = objectName.charAt(0);
            const restOfWord = objectName.slice(1);

            // Draw the first letter in a different color
            ctx.fillStyle = 'red';
            ctx.fillText(firstLetter, 512 - ctx.measureText(objectName).width / 2 + ctx.measureText(firstLetter).width / 2, 128);

            // Draw the rest of the word
            ctx.fillStyle = 'white';
            ctx.fillText(restOfWord, 512 + ctx.measureText(firstLetter).width / 2, 128);

            const objectNameTexture = new THREE.CanvasTexture(objectNameCanvas);
            const objectNameMaterial = new THREE.SpriteMaterial({ map: objectNameTexture });
            const objectNameSprite = new THREE.Sprite(objectNameMaterial);
            objectNameSprite.position.set(460, 430, 0);
            objectNameSprite.scale.set(300, 75, 1);
            objectNameSprite.name = `ObjectName_${nextLetter}`;
            scene.add(objectNameSprite);

            // Update spotLight2 intensity
            spotLight2.intensity = objectMapping.light_intensity;

            // Play the corresponding audio
            const audio = new Audio(`assets/audios/${nextLetter.toLowerCase()}.mp3`);
            audio.play().catch(error => {
                console.error('Error playing audio:', error);
            });

            isSwapping = false; // Reset the flag
        }, undefined, (error) => {
            console.error('An error happened while loading the object:', error);
            isSwapping = false; // Reset the flag in case of error
        });
    }

    // Combined event listener for both click and touch
    const handleInteraction = (event) => {
        // Prevent default touch behavior for touch events
        if (event.type === 'touchstart') {
            event.preventDefault();
        }

        // Calculate mouse position in normalized device coordinates
        const clientX = event.type === 'touchstart' ? event.touches[0].clientX : event.clientX;
        const clientY = event.type === 'touchstart' ? event.touches[0].clientY : event.clientY;

        mouse.x = (clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(clientY / window.innerHeight) * 2 + 1;

        // Update the picking ray with the camera and mouse position
        raycaster.setFromCamera(mouse, camera);

        // Calculate objects intersecting the picking ray
        const intersects = raycaster.intersectObjects(scene.children, true);

        if (intersects.length > 0) {
            // Get the first intersected object
            const object = intersects[0].object;

            // Traverse up the object hierarchy to find a named parent
            let currentObject = object;
            while (currentObject && !currentObject.name) {
                currentObject = currentObject.parent;
            }

            // Log the name of the object or its named parent
            if (currentObject && currentObject.name) {
                console.log('Interacted with:', currentObject.name);
                if (currentObject.name === 'Christmas_Tree_Christmas_Tree_0') { // Fixed the name to match the tree
                    shakeObject(currentObject);
                    swapBlock();
                    if (!hasInteractedWithTree) {
                        hasInteractedWithTree = true;
                        // Remove the instruction text
                        if (instructionSprite) {
                            scene.remove(instructionSprite);
                            instructionSprite = null;
                        }
                    }
                }
            } else {
                console.log('Interacted with an unnamed object');
            }
        }
    };

    window.addEventListener('click', handleInteraction);
    window.addEventListener('touchstart', handleInteraction);

    // Animate
    const tick = (timeStamp) => {
        orbitControls.update()

        dropSnows(timeStamp)

        // Update shaking animation
        if (isShaking) {
            shakeTime += 16; // Assuming 60 FPS
            if (shakeTime < shakeDuration) {
                const intensity = Math.sin(shakeTime * Math.PI / shakeDuration) * shakeIntensity;
                const treeObject = scene.getObjectByName('Christmas_Tree_Christmas_Tree_0'); // Fixed the name to match the tree
                if (treeObject) {
                    treeObject.rotation.x = intensity * (Math.random() - 0.5);
                    treeObject.rotation.z = intensity * (Math.random() - 0.5);
                }
            } else {
                isShaking = false;
                const treeObject = scene.getObjectByName('Christmas_Tree_Christmas_Tree_0'); // Fixed the name to match the tree
                if (treeObject) {
                    treeObject.rotation.x = 0;
                    treeObject.rotation.z = 0;
                }
            }
        }

        renderer.render(scene, camera)

        window.requestAnimationFrame(tick)
    }
    window.requestAnimationFrame(tick)
}
